<template>
  <div class="d-flex flex-column align-center">
    <v-icon size="6em">mdi-file</v-icon>
    {{ size }}
    <b>{{ paste.fileName }}</b>

    <v-btn
        color="primary"
        :href="downloadUri"
        :download="paste.fileName"
        small>
      Download
    </v-btn>
  </div>
</template>

<script>
import { humanFileSize } from '@/lib/utility'
import { URL_API } from '@/lib/constants'

export default {
  name: "FileDetail",
  props: {
    paste: Object,
  },
  data: () => ({
    size: 0,
  }),
  mounted() {
    this.size = humanFileSize(this.paste.size)
  },
  computed: {
    downloadUri() {
      return `${URL_API}/paste/${this.paste.hashId}/raw`
    },
  },
}
</script>

<style scoped>

</style>